import React from "react";
import { Col, Row } from "antd";
import { FaArrowRight } from "react-icons/fa";

function Contact() {
  return (
    <div
      style={{
        height: "calc(100vh - 70px)",
        background: "white",
        overflowY: "auto",
      }}
    >
      <div style={{ width: "100%", background: "white" }}>
        <Row className="contact-container">
          <Col
            xs={{ span: 24, order: 2 }}
            md={{ span: 12, order: 1 }}
            className="contact-col1"
          >
            <p style={{ fontWeight: "600", fontSize: "45px" }}>
              Here’s how you start
            </p>
            <p style={{ fontSize: "16px", textAlign: "left" }}>
              It’s easy to start earning with Lyft. Learn everything you need to
              know, with our simple application requirements and our insurance
              guide.
            </p>
            <button className="apply-button">Apply to Drive</button>
          </Col>
          <Col
            xs={24}
            md={12}
            order={1}
            style={{ alignItems: "center", paddingBlock: 40 }}
          >
            <img
              style={{ height: "300px", objectFit: "contain", width: "100%" }}
              src="https://images.ctfassets.net/q8mvene1wzq4/1LcqhIzCooJMH3od9Q6KWd/9ceaf0868113fcb9e9fd017f6524584f/how_start.svg?w=1500&q=60&fm="
              alt=""
            />
          </Col>
        </Row>
      </div>
      <div className="contact-div3">
        <img
          style={{ width: "100%", objectFit: "contain", marginBottom: "40px" }}
          src="https://images.ctfassets.net/q8mvene1wzq4/14j1VooOckKD3p29hMHlaz/55206618a33b79c121968f9e8eb68ab8/dvr_pgr_pp.jpg"
          alt=""
        />
        <p style={{ fontWeight: 600, fontSize: "35px", marginBottom: "20px" }}>
          {" "}
          We have your back
        </p>
        <p style={{ fontSize: "16px", marginBottom: "45px" }}>
          When you drive with Lyft, you're part of a community that prioritizes
          safety on the road. We have tools in the app for you to get help
          easily, if you ever need it. And we’re looking out for your well-being
          before, during, and after every ride.
        </p>
        <div style={{ display: "flex", alignItems: "center" }}>
          <p style={{ fontSize: "16px", fontWeight: 600 }}>
            See how you're protected
          </p>
          <FaArrowRight style={{ marginLeft: "6px" }} />
        </div>
      </div>
      <div>
        <p></p>
      </div>
    </div>
  );
}

export default Contact;
