import { Col, Row } from "antd";
import { React } from "react";
import { FaCashRegister, FaClock, FaBolt } from "react-icons/fa";

function Home() {
  return (
    <div
      gutter={24}
      style={{ overflowY: "scroll", height: "calc(100vh - 70px)" }}
    >
      <Row gutter={24} justify={"center"} className="home-container">
        <Col>
          <img
            className="home-col1"
            src="https://media.istockphoto.com/id/699803744/photo/two-young-women-having-fun-driving-along-a-street.jpg?s=612x612&w=0&k=20&c=L1F6qmDOmN3y7yx9QH_YLIXT8IWooQWtnqkC3HWkbwg="
            alt=""
          />
        </Col>
        <Col xs={24} md={8} style={{ padding: 20, marginInline: 10 }}>
          <p className="home-col2">Let's Ride</p>
          <div style={{ display: "flex", gap: "20px" }}>
            <button className="apply-button">Apply to Drive</button>
            <button className="sign-up-button">Sign up to Drive</button>
          </div>
        </Col>
      </Row>

      <Row gutter={[16, 16]} justify={"center"} className="home-row2">
        <Col xs={24} md={12} className="home-col3">
          <p className="home-col3-p1">DRIVE WITH LYFT</p>
          <p className="home-col3-p2">
            Set your own hours. Earn on your own terms.
          </p>
          <div
            style={{ display: "flex", flexDirection: "column", gap: "14px" }}
          >
            <div>
              <div style={{ display: "flex", alignItems: "center" }}>
                <FaCashRegister style={{ marginRight: "8px" }} />
                <p className="home-col3-p3">Reliable earnings:</p>
              </div>
              <p className="home-col3-p4">
                Make money, keep your tips, and cash out when you want.
              </p>
            </div>
            <div>
              {" "}
              <div style={{ display: "flex", alignItems: "center" }}>
                <FaClock style={{ marginRight: "8px" }} />
                <p className="home-col3-p6">A flexible schedule:</p>
              </div>
              <p className="home-col3-p7">
                Be your own boss and drive whenever it works for you.
              </p>
            </div>
            <div>
              <div style={{ display: "flex", alignItems: "center" }}>
                <FaBolt style={{ marginRight: "8px" }} />
                <p className="home-col-p5">Get paid instantly:</p>
              </div>
              <p className="home-col3-p8">
                Cash out your earnings whenever you want.
              </p>
            </div>
          </div>

          <div className="home-div3">
            <button className="apply-button">Apply to Drive</button>
            <p>How driver pay works?</p>
          </div>
        </Col>
        <Col xs={24} md={8}>
          <img
            style={{ objectFit: "contain", width: "100%", height: 400 }}
            src="https://images.ctfassets.net/q8mvene1wzq4/2qig3wGIPssGl5C9tS0Lrt/f68bb064f872385abc6e95606cb0794e/home_pinkpaint_driver.jpg?w=1500&q=60&fm=webp"
            alt=""
          />
        </Col>
      </Row>
    </div>
  );
}
export default Home;
