import React from "react";
import { Col, Row } from "antd";

function About() {
  return (
    <div style={{ overflowY: "auto", height: "calc(100vh - 70px)" }}>
      <div style={{ width: "100%", background: "white", paddingBlock: 20 }}>
        <Row className="about-container">
          <Col
            className="gutter-row"
            xs={24}
            md={12}
            style={{ alignItems: "center" }}
          >
            <img
              style={{ height: "53vh", objectFit: "contain", width: "100%" }}
              src="https://images.ctfassets.net/q8mvene1wzq4/2U9CYHaJUPoUS5s00qjNh8/a7626e178402e61484b777918e1407b3/dvr_hero_pp_V2.jpg?w=2500&q=60&fm=webp"
              alt=""
            />
          </Col>
          <Col
            className="gutter-row"
            xs={24}
            md={12}
            // style={{ height: "53vh" }}
          >
            <div className="about-div2">
              <p className="about-col2-p1">Make every day payday</p>
              <p style={{ textAlign: "left", fontSize: "12px" }}>
                By providing your phone number and clicking “Apply to drive”,
                you consent to receive text messages from Lyft. Text messages
                may be autodialed, and data rates may apply. The frequency of
                text messages varies. You may text STOP to cancel any time.
              </p>
              <button className="apply-button">Apply to Drive</button>
            </div>
          </Col>
        </Row>
      </div>
      <div style={{ width: "100%", background: "white", paddingTop: "10vh" }}>
        <Row
          gutter={16}
          style={{ width: "80%", margin: "auto", alignItems: "center" }}
        >
          <Col xs={24} md={12} className="about-col3">
            <p style={{ fontWeight: "600", fontSize: "33px" }}>
              Cash out right away
            </p>
            <p style={{ fontSize: "16px", textAlign: "left" }}>
              Want your cash fast? Get paid in just around an hour or two with
              Express Pay. Or sign up for a Lyft Direct debit card for instant
              access to earnings, after every ride.
            </p>
            <p style={{ fontSize: "16px", fontWeight: 600 }}>
              Read all about driver pay
            </p>
          </Col>
          <Col className="gutter-row" xs={24} md={12}>
            <img
              style={{ height: "38vh", objectFit: "contain", width: "100%" }}
              src="https://images.ctfassets.net/q8mvene1wzq4/5Zfeee22Bn598fKC5AIBHa/08218240dd542e6153faf2ed91964f28/0413_HOSPITAL_4677-V02_1.jpg?w=1500&q=60&fm=webp"
              alt=""
            />
          </Col>
        </Row>
      </div>
    </div>
  );
}

export default About;
