import React, { useEffect, useState } from "react";
import { Rnd } from "react-rnd";
import useWindowDimensions from "../hooks/useWindowDimensions";
import { MdOutlineDragIndicator } from "react-icons/md";

const DCall = ({
  uniqueId,
  setPlController,
  setShowParticipantList,
  userDetails,
  callInfo,
  setCallInfo,
  setShowChat,
  onCollectedUserDetails,
}) => {
  const { width, height } = useWindowDimensions();

  const [load, setLoad] = useState(false);
  const [callMode, setCallMode] = useState("AUDIO");
  const [invitee, setInvitee] = useState({});
  const [from_group, setFromGroup] = useState(null);
  const [connected, setConnected] = useState(false);

  useEffect(() => {
    if (callInfo) {
      console.log("rendering call");
      setCallMode(callInfo?.callMode);
      setInvitee(callInfo?.callInviteData);
      if (callInfo?.from_group) {
        setFromGroup(callInfo?.from_group);
      }
      setLoad(true);
    }
  }, [callInfo]);

  window.onError = (ex) => {
    console.log(ex);

    ex.proceed();
    setTimeout(() => {
      setCallInfo(null);
    }, 5000);
  };

  window.onCallModeChange = (d) => {
    if (d?.mode === "audio") {
      setCallMode("AUDIO");
    } else {
      setCallMode("VIDEO");
    }
  };

  window.onCallEnded = () => {
    setCallInfo(null);
    setConnected(false);
  };

  window.onCallEvent = (d) => {
    console.log(d);
    d?.controller?.addEventListener("videoConferenceJoined", () => {
      console.log("Call Joined");
      setConnected(true);
    });

    d?.controller?.addEventListener("openParticipantList", () => {
      console.log("Show Particiapant list");
      setShowParticipantList(true);
      setPlController(d?.controller);
    });

    d?.controller?.addEventListener("closeParticipantList", () => {
      console.log("hide Particiapant list");
      setShowParticipantList(false);
      setPlController(null);
    });

    d?.controller?.addEventListener("showNotepad", () => {
      console.log("Show Notepad Chat");
      setShowChat(true);
    });
    d?.controller?.addEventListener("openChat", () => {
      console.log("Open Chat");
      setShowChat(true);
    });
    d?.controller?.addEventListener("closeChat", () => {
      console.log("Close Chat");
      setShowChat(false);
    });

    d?.controller?.addEventListener("readyToClose", () => {
      setShowParticipantList(false);
      setPlController(null);
    });

    d?.controller?.addEventListener("errorOccurred", () => {
      setShowParticipantList(false);
      setPlController(null);
    });
    d.proceed();
  };

  const getCurrentUnixTime = () => {
    return Math.floor(Date.now() / 1000);
  };

  window.onMeetingData = (d) => {
    console.log("meeting data :", d);
    const chat_group_id = d?.data?.chat_group_id;
    console.log("chat group id :", chat_group_id);
    localStorage.setItem(
      "X-Cache-Session",
      `${window.mq.getMucJID(chat_group_id)}`
    );
    localStorage.setItem(uniqueId, d?.data?.meeting_info_id);
    localStorage.setItem("X-Cache-Expiry", getCurrentUnixTime() + 3600);
    d?.proceed();
  };

  window.onCollectedUserDetails = onCollectedUserDetails;

  return (
    <React.Fragment>
      {load && (
        <Rnd
          default={{
            x: width - 400,
            y: 50,
          }}
          style={{
            zIndex: 1000,
            boxShadow: "rgba(0, 0, 0, 0.4) 0px 5px 20px",
            borderRadius: 15,
            backgroundColor: connected ? "#018fce" : "#fff",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            overflow: "hidden",
          }}
          bounds="window"
          dragHandleClassName="dhandle"
          enableResizing={false}
          minHeight={callMode === "AUDIO" ? 119 : 300}
          minWidth={350}
          maxWidth={350}
          // minHeight={119}
        >
          <div
            className="flex justify-center align-center dhandle"
            style={{
              position: "absolute",
              left: "calc(50% - 50px)",
              cursor: "move",
              width: "100px",
              zIndex: 1000,
              color: "#fff",
              top: 0,
            }}
            onDrag={() => {
              console.log("drag");
            }}
          >
            <MdOutlineDragIndicator style={{ rotate: "90deg" }} />
          </div>

          <caller-component
            unique-id={`${uniqueId}`}
            style={{
              width: "100%",
              borderRadius: "15px",
              overflow: "hidden",
              height: "100%",
              position: callMode === "VIDEO" && "absolute",
            }}
            web-client-uri={callInfo?.webClientUri}
            call-type={callMode}
            init-chat={"true"}
            current-user-id={userDetails?.uuid}
            call-invite-data={JSON.stringify({
              ...invitee,
            })}
            on-error={"onError"}
            on-call-mode-change={"onCallModeChange"}
            on-call-ended={"onCallEnded"}
            on-meeting-data={"onMeetingData"}
            strip-options={JSON.stringify({ expandedHeight: "300px" })}
            additional-call-props={JSON.stringify({
              extParticipantPane: true,
              extChat: true,
            })}
            call-event={"onCallEvent"}
            override-init-meeting-data={JSON.stringify({
              mute_audio_on_start: false,
              from_group: from_group,
            })}
            on-collected-details="onCollectedUserDetails"
          ></caller-component>
        </Rnd>
      )}
    </React.Fragment>
  );
};

export default DCall;
