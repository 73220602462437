import React from "react";
import { MdOutlineDragIndicator } from "react-icons/md";
import { Rnd } from "react-rnd";

const ParticipantList = ({ controller, uniqueId }) => {
  return (
    <Rnd
      default={{
        x: 50,
        y: 50,
      }}
      style={{
        zIndex: 900,
        boxShadow: "rgba(0, 0, 0, 0.4) 0px 5px 20px",
        borderRadius: 15,
        backgroundColor: "#fff",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        overflow: "hidden",
      }}
      bounds="window"
      dragHandleClassName="dhandle"
      enableResizing={false}
      minWidth={400}
      minHeight={500}
    >
      <div
        className="flex justify-center align-center dhandle"
        style={{
          position: "absolute",
          left: "calc(50% - 50px)",
          cursor: "move",
          width: "100px",
          zIndex: 900,
          color: "#000",
          top: 0,
        }}
        onDrag={() => {
          console.log("drag");
        }}
      >
        <MdOutlineDragIndicator style={{ rotate: "90deg" }} />
      </div>
      <participant-list-component
        style={{
          width: "100%",
          height: "100%",
          position: "absolute",
          padding: 5,
        }}
        unique-id={`${uniqueId}`}
        meeting-info-id={`${localStorage.getItem(uniqueId)}`}
        current-user-id={
          JSON.parse(localStorage.getItem("X-DVC-Details"))?.["uuid"]
        }
      ></participant-list-component>
    </Rnd>
  );
};

export default ParticipantList;
