import "./App.css";
import React, { useCallback, useEffect, useState } from "react";
import { Layout, Menu, FloatButton, Popover, Drawer, message } from "antd";
import { Navigate, Route, Routes } from "react-router-dom";
import Home from "./pages/Home";
import About from "./pages/About";
import Contact from "./pages/Contact";
import { useNavigate } from "react-router-dom";

import Chat from "./web/Chat";
import { AiTwotonePhone } from "react-icons/ai";
import DCall from "./web/DCall";
import { LoadingOutlined } from "@ant-design/icons";
import ParticipantList from "./web/ParticipantList";
import { RiMessage3Fill } from "react-icons/ri";
import Dev from "./components/dev";
import VirtualAgent from "./components/va";
import ChatVirtualAgent from "./components/vac";
import { SAMPLE_SERVER_URL } from "./config";

const { Header } = Layout;

const items = [
  {
    key: "",
    label: "Home",
    path: "/",
  },
  {
    key: "about",
    label: "About",
    path: "/about",
  },
  {
    key: "contact",
    label: "Contact",
    path: "/contact",
  },
];

const getCurrentUnixTime = () => {
  return Math.floor(Date.now() / 1000);
};

function App() {
  const navigate = useNavigate();

  const [callInfo, setCallInfo] = useState(null);
  const [showChat, setShowChat] = useState(false);
  const [loading, setLoading] = useState(true);

  const [uniqueId, setUniqueId] = useState("1w6f6f6");
  const [showParticipantList, setShowParticipantList] = useState(false);
  const [plController, setPlController] = useState(null);
  const [uDetails, setUDetails] = useState(null);

  const [endpointConfig, setEndpointConfig] = useState(null);

  const [virtualUserId, setVirtualUserId] = useState(
    localStorage.getItem("va_selected")
  );
  const [virtualChatUserId, setVirtualChatUserId] = useState(
    localStorage.getItem("vac_selected")
  );
  const [serverUrl, setServerUrl] = useState(localStorage.getItem("server_selected"));

  const getLocalData = (key) => {
    const ud = localStorage.getItem(key);
    if (ud) {
      try {
        const jsonUd = JSON.parse(ud);
        return jsonUd;
      } catch (err) {
        console.log(err);
      }
    }
    return null;
  };

  function removeProtocol(url) {
    console.log("remove protocol", url);
    const v = Array.isArray(url) ? url[0] : url;
    return v.replace(/^https?:\/\//, "");
  }

  function addScriptToHead(url) {
    // Check if a script with the given URL already exists in the document
    const existingScript = document.querySelector(
      `script[src="${url}/console/external_api.js"]`
    );

    if (!existingScript) {
      // Create a new script element
      const scriptElement = document.createElement("script");
      scriptElement.src = `${url}/console/external_api.js`;

      // Append the script element to the head of the document
      document.head.appendChild(scriptElement);
    }
  }

  useEffect(() => {
    const endpointConfig = localStorage.getItem("X-Api-Endpoint")
      ? localStorage.getItem("X-Api-Endpoint")
      : serverUrl;

    if (endpointConfig) {
      addScriptToHead(endpointConfig);
    }
    setEndpointConfig({
      apiEndpoint: endpointConfig,
      sampleEndpoint:
        process.env.NODE_ENV === "development"
          ? SAMPLE_SERVER_URL
          : window?._env_?.REACT_APP_AUTH_SERVER_URL
          ? window?._env_?.REACT_APP_AUTH_SERVER_URL
          : window.location.origin,
    });
  }, [serverUrl]);

  useEffect(() => {
    if (endpointConfig && endpointConfig.apiEndpoint) {
      const xq = new window.XQ(
        endpointConfig.apiEndpoint,
        endpointConfig.sampleEndpoint
      );

      window.xq = xq;

      console.log("run");

      const userDetails = getLocalData("X-DVC-Details");
      const vid = localStorage.getItem("va_selected");

      if (vid) {
        setVirtualUserId(vid);
      }

      if (userDetails) {
        getSDK(
          userDetails,
          () => {},
          () => {
            setLoading(false);
          }
        );
      } else {
        setLoading(false);
      }
    } else {
      setLoading(false);
    }
  }, [endpointConfig]);

  const onAuthSuccess = ({ responseData, token, userLocalDetails, onC }) => {
    console.log("Extracted SDK Token !");
    const userDetails = window?.xq?.getLocalData("X-DVC-Details");
    setUDetails(userDetails);
    if (onC) {
      onC();
    }
  };

  const onAuthEnd = () => {
    setLoading(false);
  };

  const getSDK = (d, onSuccess, onEnd = () => {}) => {
    console.log(d);
    const data = d?.data ? d?.data : d;
    if (data["verified"]) {
      console.log(data);

      const arr = data?.["name"]
        ? data?.["name"]?.split(" ")
        : data?.["full_name"]?.split(" ");
      const first_name = arr?.[0];
      const last_name = arr?.[1];

      window?.xq?.getSdkAuth(
        {
          username: data?.email ? data?.email : data?.mobile_no,
          first_name: first_name ? first_name : null,
          last_name: last_name ? last_name : null,
          tenant_domain: removeProtocol(endpointConfig.apiEndpoint),
        },
        onAuthSuccess,
        ({ err }) => {
          console.log(`auth failed : ${err}`);
          if (d?.cancel) {
            d?.cancel();
          }
        },
        onEnd,
        { onC: onSuccess, userLocalDetails: data }
      );
    } else {
      setLoading(false);
    }
  };

  return (
    <div className="App" style={{ height: "100%" }}>
      <div
        style={{
          width: "100%",
          backgroundColor: "black",
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <h4 className="flex" style={{ color: "#fff", paddingInline: 20 }}>
          Developer Mode
        </h4>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-end",
            padding: "10px",
            gap: 10,
          }}
        >
          <ChatVirtualAgent
            onChange={(agent) => {
              console.log("change chat agent", agent);
              setVirtualChatUserId(agent);
              if (showChat) {
                setShowChat(false);
              }
              if (window?.destroyChat) {
                window?.destroyChat();
                window?.mq?.disconnect();
              }
            }}
          />
          <VirtualAgent
            onChange={(agent) => {
              console.log("change agent", agent);
              setVirtualUserId(agent);
              if (showChat) {
                setShowChat(false);
              }
              if (window?.destroyChat) {
                window?.destroyChat();
                window?.mq?.disconnect();
              }
            }}
          />
          <Dev
            onServerChange={(url) => {
              console.log("change server", url);
              setServerUrl(url);
              if (showChat) {
                setShowChat(false);
              }
              if (window?.destroyChat) {
                window?.destroyChat();
                window?.mq?.disconnect();
              }
            }}
          />
        </div>
      </div>

      {loading && (
        <React.Fragment>
          <div className="loading-holder">
            <LoadingOutlined style={{ fontSize: 60, color: "#EA2A8D" }} spin />
          </div>
        </React.Fragment>
      )}
      {!loading && (
        <Layout>
          {callInfo && (
            <DCall
              uniqueId={uniqueId}
              setPlController={setPlController}
              setShowParticipantList={setShowParticipantList}
              userDetails={uDetails}
              callInfo={callInfo}
              setCallInfo={setCallInfo}
              setShowChat={(v) => {
                if (v) {
                  if(window.setChat){
                    window?.setChat();
                  }
                  setShowChat(v);
                } else {
                  if(window.destroyChat){
                    window?.destroyChat();
                  }
                  setShowChat(v);
                }
              }}
              onCollectedUserDetails={(d) => {
                console.log("collected user details : ", d);
                if (d?.data?.mode === "submit") {
                  d?.proceed();
                } else if (d?.data?.mode === "verify") {
                  const onSuccess = () => {
                    d?.proceed();
                  };
                  getSDK(d, onSuccess);
                } else {
                  d?.proceed();
                }
              }}
            />
          )}
          <div style={{}}>
            <Popover
              placement="leftTop"
              content={
                <Chat
                  isFocused={showChat}
                  virtualUserId={virtualChatUserId}
                  hidePower={false}
                  callInfo={callInfo}
                  showChat={showChat}
                  setShowChat={setShowChat}
                  onCallInit={(callMode, webClientUri) => {
                    setCallInfo({
                      callMode,
                      webClientUri,
                    });
                  }}
                  onCollectedUserDetails={(d) => {
                    console.log("collected user details : ", d);
                    if (d?.data?.mode === "submit") {
                      d?.proceed();
                    } else if (d?.data?.mode === "verify") {
                      const onSuccess = () => {
                        d?.proceed();
                      };
                      getSDK(d, onSuccess);
                    } else {
                      d?.proceed();
                    }
                  }}
                />
              }
              open={showChat}
              // onOpenChange={(visible) => {
              //   if (!visible && window?.chatError === true) {
              //     window?.destroyChat();
              //   }
              //   if (visible && window.setChat) {
              //     window.chatError = false;
              //     window?.setChat();
              //   }
              //   setShowChat(visible);
              // }}
              // trigger="click"
              style={{ borderRadius: 20 }}
              // destroyTooltipOnHide={true}
            >
              <FloatButton
                onClick={() => {
                  if (serverUrl && virtualChatUserId) {
                    window.chatError = false;
                    if (window?.setChat) {
                      window?.setChat();
                    }
                    setShowChat(true);
                  } else {
                    message.info("Set Server & Chat Virtual Agent to proceed");
                  }
                }}
                icon={<RiMessage3Fill />}
                type="primary"
                className="float-chat"
              />
            </Popover>
            <FloatButton
              icon={
                <AiTwotonePhone style={{ rotate: "90deg", color: "#fff" }} size={20} />
              }
              type="default"
              style={{ right: 34, bottom: 54 }}
              prefixCls="call-btn"
              onClick={() => {
                if (serverUrl && virtualUserId) {
                  setCallInfo({
                    callMode: "AUDIO",
                    callInviteData: {
                      dvc: [virtualUserId],
                      telephony: [],
                    },
                  });
                } else {
                  message.info("Set Server & Virtual Agent to proceed");
                }
              }}
            />

            {showParticipantList && plController && uniqueId && (
              <ParticipantList uniqueId={uniqueId} controller={plController} />
            )}
            <Header
              style={{
                display: "flex",
                alignItems: "center",
                backgroundColor: "#fff",
              }}
            >
              <a
                href="/"
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  width: 100,
                }}
              >
                <img
                  style={{
                    objectFit: "contain",
                    height: "30px",
                    marginLeft: "2%",
                    marginRight: "1%",
                  }}
                  src="https://upload.wikimedia.org/wikipedia/commons/thumb/a/a0/Lyft_logo.svg/1200px-Lyft_logo.svg.png"
                  alt=""
                />
              </a>
              <Menu
                theme="light"
                mode="horizontal"
                defaultSelectedKeys={[window.location.pathname.replace("/", "")]}
                className="menu"
                style={{ border: "none", width: "100%" }}
                items={items}
                onClick={(e) => {
                  navigate(`/${e?.key}`);
                }}
              />
            </Header>
          </div>
          <Routes>
            <Route path="/" element={<Home />}></Route>
            <Route path="/about" element={<About />}></Route>
            <Route path="/contact" element={<Contact />}></Route>
            <Route path="*" element={<Navigate to="/" />}></Route>
          </Routes>
          <div className="sticky-footer">© 2023 Powered by lyft.</div>
        </Layout>
      )}
    </div>
  );
}

export default App;
